import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import { Button, ButtonSizes } from '../../../../../shared/styles/button';
import { Container, ContainerContentFull, ContainerContentStandard } from '../../../../../shared/styles/container';
import { device } from '../../../../../shared/styles/device';
import { Headline, SubHeadline } from '../../../../../shared/styles/headline';

const TalentsSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
`;
const TalentsGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 32px;
  position: relative;
  
  @media ${device.tablet} {
    grid-template-columns: minmax(0, 1fr) minmax(350px, 1fr) minmax(0, 1fr);
    grid-column-gap: 64px;
    grid-row-gap: 0;
    max-height: 100vh;
  }
`;
const PictureColumnDesktop = styled.div`
  display: none;
  visibility: hidden;
  position: relative;
  
  @media ${device.tablet} {
    max-height: 900px;
    height: 900px;
    display: flex;
    visibility: visible;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }

  .hero-img {
      height: 100%;
      width: 100%;
  }
`;
const PictureColumnMobile = styled.div`
  visibility: visible;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 366px;
  
  @media ${device.tablet} {
    display: none;
    visibility: hidden;
  }

  .hero-img {
      height: 100%;
      width: 100%;
  }
`;
const TextColumnMobile = styled.div`
  visibility: visible;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  justify-content: center;

  .margin-normal {
    margin-bottom: 32px !important;
  }
  button {
        margin-left: auto;
    margin-right: auto;
  }
  
  @media ${device.tablet} {
    display: none;
    visibility: hidden;
  }
`;
const TextColumn = styled.div`
  display: none;
  visibility: hidden;

  .margin-normal {
    margin-bottom: 32px !important;
  }

    @media ${device.tablet} {
      visibility: visible;
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: center;
      max-width: 600px;
      margin: auto;
  }
`;

export const TalentsSection = () => {
  return (
    <TalentsSectionContainer>
      <Container>
        <ContainerContentFull>
          <TalentsGrid>
            <PictureColumnMobile>
              <Image
                src="/images/talentpool/landing/mobile.jpg"
                alt="Stell-Mich-Ein Treffen Meeting"
                layout='fill'
                quality={100}
                className="hero-img"
                objectFit="contain"
                objectPosition="center"
              />
            </PictureColumnMobile>
            <PictureColumnDesktop>
              <Image
                src="/images/talentpool/landing/left.jpg"
                alt="Stell-Mich-Ein Treffen Meeting"
                layout='fill'
                quality={100}
                className="hero-img"
                objectFit="contain"
                objectPosition="right"
              />
            </PictureColumnDesktop>
            <TextColumn>
              <Headline>Starte deine Karriere in der Kreativszene.</Headline>
              <SubHeadline className="margin-normal">
                Bist du auf der Suche nach einem neuen Job in der Kommunikationsbranche? <br />
                Dann werde Teil des STELL-MICH-EIN-Talentpools! <br />
                Du wirst in unserem Talentpool während und nach deinem Studium/deiner Ausbildung und als Berufsanfänger*in mit bis zu zwei Jahren Erfahrung aufgenommen. <br />
                Beim STELL-MICH-EIN-Talentpool kommt der Job zu dir – ob Praktikum, Werkstudententätigkeit, Volontariat oder Festanstellung!
              </SubHeadline>
              <Link href="/register/talent">
                <Button primary type="button" size={ButtonSizes.l}>Jetzt mitmachen</Button>
              </Link>
            </TextColumn>
            <PictureColumnDesktop>
              <Image
                src="/images/talentpool/landing/right.jpg"
                alt="Stell-Mich-Ein Treffen Meeting"
                layout='fill'
                quality={100}
                className="hero-img"
                objectPosition="left"
                objectFit="contain"
              />
            </PictureColumnDesktop>
          </TalentsGrid>
        </ContainerContentFull>
        <ContainerContentStandard>
          <TextColumnMobile>
            <Headline>Starte deine Karriere in der Kreativszene.</Headline>
            <SubHeadline className="margin-normal">
              Bist du auf der Suche nach einem neuen Job in der Kommunikationsbranche? <br />
              Dann werde Teil des STELL-MICH-EIN-Talentpools! <br />
              Du wirst in unserem Talentpool während und nach deinem Studium/deiner Ausbildung und als Berufsanfänger*in mit bis zu zwei Jahren Erfahrung aufgenommen. <br />
              Beim STELL-MICH-EIN-Talentpool kommt der Job zu dir – ob Praktikum, Werkstudententätigkeit, Volontariat oder Festanstellung!
            </SubHeadline>
            <Link href="/register/talent">
              <Button primary size={ButtonSizes.l}>Jetzt mitmachen</Button>
            </Link>
          </TextColumnMobile>
        </ContainerContentStandard>
      </Container>
    </TalentsSectionContainer>
  )
}
