import Image from 'next/image';
import styled from 'styled-components';
import { Container, ContainerContentFull, ContainerContentStandard } from '../../../../../shared/styles/container';
import { device } from '../../../../../shared/styles/device';
import { Headline, SubHeadline } from '../../../../../shared/styles/headline';
/* import PanoramaSVG from '/public/svg/talentpool/agenturen/panorama.svg'; */
import AdelLinkSVG from '/public/svg/talentpool/agenturen/adel_link.svg';
import FrSvg from '/public/svg/talentpool/agenturen/fr-logo-new.svg';
import MutaborSVG from '/public/svg/talentpool/agenturen/mutabor.svg';
import PhSvg from '/public/svg/talentpool/agenturen/ph.svg';
import ThjnkSvg from '/public/svg/talentpool/agenturen/thjnk.svg';
import VmlyrSvg from '/public/svg/talentpool/agenturen/vmlyr.svg';

const AgenturenSectionContainer = styled.section`
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 8px;
  }
  h3 {
    margin-bottom: 48px;
  }
`;
const AgenturGridContainer = styled.div`
  position: relative;
  padding-top: 0px;
  padding-bottom: 64px;

  &>div:first-child {
    margin-bottom: 64px;
  }

  @media ${device.tablet} {
    padding: 64px 0;
    &::after {
      background-image: -webkit-linear-gradient(left,rgb(255 255 255) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0) 80%,rgb(255 255 255) 100%);
      background-image: linear-gradient(90deg, white 0%, rgba(255,255,255,0) 20%, rgba(255,255,255,0) 80%, white 100%);
      inset: -20px 0px;
      content: "";
      position: absolute;
    }
  } 

  &::after {
    background-image: -webkit-linear-gradient(left,rgb(255 255 255) 0%,rgba(0,0,0,0) 10%,rgba(0,0,0,0) 90%,rgb(255 255 255) 100%);
    background-image: linear-gradient(90deg, white 0%, rgba(255,255,255,0) 10%, rgba(255,255,255,0) 90%, white 100%);
    inset: -20px 0px;
    content: "";
    position: absolute;
  }
`
const AgenturenImageRow = styled.div`
  display: grid;
  margin-left: auto;
  margin-right: auto;
  row-gap: 32px;
  column-gap: 6px;
  grid-template-columns: 1fr 1fr;
  row-gap: 22px;
  max-width: 1200px;
  overflow-x: hidden;    

  overflow-y: hidden;
    
  svg {
    max-height: 60px;
    max-width: 160px;
    margin: auto;
  }

  .svg-fr {
    height: 60px;
    width: 160px;
  }

  .agentur-col {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;

    .agentur-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 60px;
    }
  }

  .gatsby-image-wrapper {
    margin: auto;
  }

   @media ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 0;
    column-gap: 18px;
  } 
`;

export const AgenturenSection = () => {
  return (
    <AgenturenSectionContainer>
      <Container centered>
        <ContainerContentStandard>
          <Headline>Unsere Unternehmen</Headline>
          <SubHeadline>STELL-MICH-EIN begleitet seit Jahren die Top-Unternehmen der Branche</SubHeadline>
        </ContainerContentStandard>
        <ContainerContentFull>
          <AgenturGridContainer>
            <AgenturenImageRow>
              <div className="agentur-col">
                <div className="agentur-item">
                  <Image
                    src="/images/talentpool/landing/agenturen/buddybrand.png"
                    alt="Agentur Buddybrand"
                    layout='fixed'
                    height={60}
                    width={160}
                    quality={100}
                    loading="eager"
                    objectPosition="contain"
                    objectFit='contain'
                  />
                </div>
              </div>
              <div className="agentur-col">
                <div className="agentur-item">
                  <Image
                    src="/images/talentpool/landing/agenturen/panorama.png"
                    alt="Agentur Panorama3000"
                    layout='fixed'
                    height={60}
                    width={160}
                    quality={100}
                    loading="eager"
                    objectPosition="contain"
                    objectFit='contain'
                  />
                </div>
              </div>
              <div className="agentur-col">
                <div className="agentur-item">
                  <Image
                    src="/images/talentpool/landing/agenturen/raysono.png"
                    alt="Agentur raysono"
                    layout='fixed'
                    height={60}
                    width={160}
                    quality={100}
                    loading="eager"
                    objectPosition="contain"
                    objectFit='contain'
                  />
                </div>
              </div>
              <div className="agentur-col">
                <div className="agentur-item">
                  <AdelLinkSVG />
                </div>
              </div>
              <div className="agentur-col">
                <div className="agentur-item">
                  <FrSvg className="svg-fr" />
                </div>
              </div>
              <div className="agentur-col">
                <div className="agentur-item">
                  <MutaborSVG />
                </div>
              </div>
            </AgenturenImageRow>
            <AgenturenImageRow>
              <div className="agentur-col">
                <div className="agentur-item">
                  <PhSvg />
                </div>
              </div>
              <div className="agentur-col">
                <div className="agentur-item">
                  <ThjnkSvg />
                </div>
              </div>
              <div className="agentur-col">
                <div className="agentur-item">
                  <VmlyrSvg />
                </div>
              </div>
              <div className="agentur-col">
                <div className="agentur-item">
                  <Image
                    src="/images/talentpool/landing/agenturen/fischer.png"
                    alt="Agentur Fischer"
                    layout='fixed'
                    height={60}
                    width={160}
                    quality={100}
                    loading="eager"
                    objectPosition="contain"
                    objectFit='contain'
                  />
                </div>
              </div>
              <div className="agentur-col">
                <div className="agentur-item">
                  <Image
                    src="/images/talentpool/landing/agenturen/rsm.png"
                    alt="Agentur RSM"
                    layout='fixed'
                    height={60}
                    width={160}
                    quality={100}
                    loading="eager"
                    objectPosition="contain"
                    objectFit='contain'
                  />
                </div>
              </div>
              <div className="agentur-col">
                <div className="agentur-item">
                  <Image
                    src="/images/talentpool/landing/agenturen/strichpunkt.png"
                    alt="Agentur Strichpunkt"
                    layout='fixed'
                    height={60}
                    width={160}
                    quality={100}
                    loading="eager"
                    objectPosition="contain"
                    objectFit='contain'
                  />
                </div>
              </div>
            </AgenturenImageRow>
          </AgenturGridContainer>
        </ContainerContentFull>
      </Container>
    </AgenturenSectionContainer>
  )
}
