import styled from "styled-components";
import { EventBannerBlock } from "../../../../shared/blocks/EventBannerBlock";
import { getPageBlocks } from "../../../../shared/lib/api";
import { device } from "../../../../shared/styles/device";
import Layout from "../../components/global/Layout";
import Seo from "../../components/global/Seo";
import { AreasSection } from "../../components/speed-recruiting/Areas";
import { MediaSection } from "../../components/speed-recruiting/Media";
import { AgenturenSection } from "../../components/talentpool/talente/agenturen";
import { TPHeroIntro } from "../../components/talentpool/talente/heroIntro";
import { ProcessSection } from "../../components/talentpool/talente/process";
import { TalentsSection } from "../../components/talentpool/talente/talents";

const IndexContainer = styled.div`
    &>section {
      margin-bottom: 64px;
    }

    @media ${device.tablet} {
      &>section {
        margin-bottom: 128px;
      }
    }
`;

const LandingPage = ({ blocks }) => {

  const title = "Dein neuer Job in der Kommunikationsbranche"
  const description = "Bist du auf der Suche nach einem neuen Job in Werbung, PR und Kommunikation? Dann werde Teil des STELL-MICH-EIN-Talentpools!"

  return (
    <>
      <Seo title={title} description={description} />
      <Layout preview={false} headerAbsolute={false} showCallToAction>
        <IndexContainer>
          <TPHeroIntro />
          <AgenturenSection />
          <TalentsSection />
          <ProcessSection />
          <AreasSection />
          {
            blocks.map((block, index) => {
              if (block.name === 'lazyblock/event-banner') {
                return <EventBannerBlock key={block.name + index} block={block}></EventBannerBlock>;
              }
            })
          }
          <MediaSection />
        </IndexContainer>
      </Layout>
    </>
  )
}

export default LandingPage

export async function getStaticProps({ preview = false, previewData }) {
  const blocks = await getPageBlocks("landingpage", preview, previewData);

  return {
    props: { blocks },
    revalidate: 120,
  }
}
