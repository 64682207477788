import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import { Button, ButtonSizes } from '../../../../../shared/styles/button';
import { device } from '../../../../../shared/styles/device';

const GridTwoCol = styled.div`
  display: grid;
  max-height: 100vh;
  height: 850px;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 850px;

  @media ${device.tablet} {
    grid-template-columns: minmax(0, 4fr) minmax(0, 3fr);
  }
`;
const HeroImageWrapper = styled.div`
  display: none;
  visibility: hidden;

  border-radius: 0 0 0 64px;

  @media ${device.tablet} {
    visibility: visible;
    background-color: #18191F;
    display: flex;
    align-items: flex-end;
    position: relative;
    max-height: 100vh;

    .hero-img {
      height: 100%;
      width: 100%;
      border-radius: 0 0 0 64px;
    }
  }
`;
const HeroTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    margin-right: 0;

    @media ${device.tablet} {
      margin-right: 42px;
      align-items: flex-end;
    }
`;

interface HeadlineProps {
  readonly primary?: boolean;
};

const Headline = styled.h1<HeadlineProps>`
    color: ${props => props.primary ? props.theme.colors.primary : props.theme.colors.fontBlack};
    line-height: 90px;
    margin-bottom: 0;
    margin-top: 0;
    text-align: right;

    @media(max-width: 768px) {
        font-size: 36px;
        letter-spacing: -0.1rem;
        line-height: normal;
        margin-bottom: ${props => props.primary ? '32px' : '0px'};
    }
`;
const HeroText = styled.p`
    // color: ${props => props.theme.colors.fontBlack};
    margin-bottom: 32px;
    margin-top: 0;
    max-width: 500px;
    line-height: 28px;
    text-align: center;

    font-weight: 700;
    color: rgb(134, 134, 139);
    font-size: 24px;
    line-height: 31px;

  @media ${device.tablet} {
      margin-top: 16px;
      line-height: 32px;
      text-align: right;
  }
`;

export const TPHeroIntro = () => {
  return (
    <GridTwoCol>
      <HeroTextWrapper>
        <Headline>STELL-MICH-EIN</Headline>
        <Headline primary>Talentpool</Headline>
        <HeroText>
          <strong>Das Talent Network für Talente und Unternehmen der Kommunikationsbranche</strong>
        </HeroText>
        <Link href="/register">
          <Button primary size={ButtonSizes.l}>
            Jetzt loslegen
          </Button>
        </Link>
      </HeroTextWrapper>
      <HeroImageWrapper>
        <Image
          src="/images/talentpool/landing/hero.jpg"
          alt="Stell-Mich-Ein Treffen Meeting"
          layout='fill'
          quality={100}
          className="hero-img"
          objectPosition="center"
          objectFit='cover'
          priority={true}
        />
      </HeroImageWrapper>
    </GridTwoCol>
  )
}
